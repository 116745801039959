<template>
    <header class="page-title">
        <h1><i class="bi bi-user-circle"></i> アカウント情報</h1>
    </header>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <label class="form-label">Google ID</label>
                        <div class="form-readonly">{{ user.google_user_id }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">メールアドレス</label>
                        <div class="form-readonly">{{ user.google_email }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">氏名</label>
                        <div class="form-readonly">{{ user.user_name }}</div>
                    </div>
                </div>
                <div class="row mb-3 align-items-end">
                    <div class="col-9">
                        <label class="form-label">部門</label>
                        <div class="form-readonly">{{ user.division_label }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">事業部</label>
                        <div class="form-readonly">{{ user.department.department_name }}</div>
                    </div>
                    <div class="col-9">
                        <label class="form-label">権限</label>
                        <div class="form-readonly">{{ user.role_label }}</div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="$store.getters['auth/canEditProject']" class="section">
            <div class="row mb-3 align-items-end">
                <div class="col-9">
                    <label class="form-label">Chatwork アカウントID</label>
                    <form-input v-model="user.chatwork_account_id"></form-input>
                </div>
                <div class="col-9">
                    <button @click="update(user)" class="btn btn-info">保存</button>
                </div>
            </div>
        </section>
    </template>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import User from '@/models/entities/user';
import FormInput from '@/components/forms/FormInput';

export default {
    name: 'ReportFollowUp',
    components: {
        InlineLoader,
        FormInput,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            user: new User(),
        }
    },
    mounted() {
        this.loading = true;

        this.$http.get('/me')
        .then(response => {
            this.user = new User(response.data);
        })
        .finally(() => {
            this.loading = false;
        })
    },
    methods: {
        // 保存
        update() {
            this.startScreenLoading();

            this.$http.put(`/me`, this.user)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
    }
}
</script>

<style scoped>

</style>
